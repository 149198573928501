import * as React from "react"
import { Collapse, Col } from "antd"

const { Panel } = Collapse

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.Our Vision: is to revolutionise retail and wholesale electricity markets
  with software products that sharpen market forces and reward behaviours
  that support sustainable energy management.
`

const Faq = () => (
  <>
    <Col span={24}>col</Col>
    <h3 className="heading" id="faq">
      FAQ
    </h3>{" "}
    <Collapse accordion>
      <Panel header="This is panel header 1" key="1">
        <p>{text}</p>
      </Panel>
      <Panel header="This is panel header 2" key="2">
        <p>{text}</p>
      </Panel>
      <Panel header="This is panel header 3" key="3">
        <p>{text}</p>
      </Panel>
    </Collapse>
  </>
)

export default Faq
