import * as React from "react"
import SiteLayout from "../containers/sitelayout"
import Seo from "../components/seo"
import ProductSummary from "../containers/productsummary"
import Pricing from "../containers/pricing"
import Faq from "../containers/faq"

const Product = () => (
  <SiteLayout>
    <Seo title="Product" />
    {/* <ProductSummary /> */}
    {/* <Pricing /> */}
    <Faq />
  </SiteLayout>
)

export default Product
